<template>
    <date-picker @change="getDateFormat" range v-model="date_range" :shortcuts="shortcuts"
                 placeholder="Select date range"/>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment     from "moment";

let now_date = new Date();
export default {
    name      : "DateRangePicker",
    components: {DatePicker},
    props     : ['v_model'],
    data      : () => ({
        date_range: [new Date(now_date.getFullYear(), now_date.getMonth(), 1), now_date],
        shortcuts : [
            {
                text: 'Today',
                onClick() {
                    const start = new Date();
                    return [start, start];
                },
            },
            {
                text: 'Yesterday',
                onClick() {
                    const start = new Date();
                    start.setTime(start.getTime() - 24 * 3600 * 1000);
                    return [start, start];
                },
            },
            {
                text: 'Last 7 Days',
                onClick() {
                    const start = new Date();
                    start.setTime(start.getTime() - 6 * 24 * 3600 * 1000);
                    const end = new Date();
                    return [start, end];
                },
            }, {
                text: 'Last 30 Days',
                onClick() {
                    const start = new Date();
                    start.setTime(start.getTime() - 29 * 24 * 3600 * 1000);
                    const end = new Date();
                    return [start, end];
                },
            },
            {
                text: 'This Month',
                onClick() {
                    const date  = new Date();
                    const start = new Date(date.getFullYear(), date.getMonth(), 1);
                    const end   = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                    return [start, end];
                },
            }, {
                text: 'Last Month',
                onClick() {
                    const date  = new Date();
                    const start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                    const end   = new Date(date.getFullYear(), date.getMonth(), 0);
                    return [start, end];
                },
            }, {
                text: 'This Year',
                onClick() {
                    const start = new Date(new Date().getFullYear(), 0, 1);
                    const end   = new Date();
                    return [start, end];
                },
            },
        ],
    }),
    methods   : {
        getDateFormat() {
            if (this.date_range[0]) {
                let from_date                   = moment(this.date_range[0]).format('YYYY-MM-DD');
                let to_date                     = moment(this.date_range[1]).format('YYYY-MM-DD');
                this.$parent.form[this.v_model] = [from_date, to_date]
            } else {
                this.$parent.form[this.v_model] = []
            }
        }
    }
}
</script>

<style scoped>

</style>
